<template>
  <div class="w-100">
    <div class="container-fluid">
      <div class="row header-section" v-if="divisionFront">
        <img v-if="divisionFront.bg" slot="img" class="img-fluid header-section-bg" :src="divisionFront.bg.image" :srcset="`${divisionFront.bg.image_sm} 600w, ${divisionFront.bg.image_md} 1024w, ${divisionFront.bg.image} 2000w`">
        <div class="col-3 col-sm-3 col-md-2 col-lg-1">
          <img :src="divisionFront.image" :alt="divisionFront.name" class="img-fluid">
        </div>
        <div class="col-9 col-sm-9 col-md-10 col-lg-11">
          <h1 class="title">{{divisionFront.name}}</h1>
        </div>
        <div class="col-12">
          <b-button variant="primary" size="sm" class="mr-2" v-for="button in divisionFront.buttons" :key="button.caption" :target="button.showin" :href="button.link">{{button.label}}</b-button>
        </div>
        <div class="col-12 mt-3">
          <select name="season" id="season" class="form-control mb-2" @change="changeSeason($event)">
            <option :value="season.id" v-for="(season, ind) in divisionFront.seasons" :key="ind">{{ season.name }}</option>
          </select>
        </div>
        <div class="btns-floats">
          <div class="mb-2">
            <b-button :to="{ name: 'DivisionChampions', params: { lang: lang, console: console, divisionSlug: divisionFront.slug } }" variant="outline-primary" class="btn-icon">
              <img src="../../../assets/images/champions.png" alt="Campeones">
            </b-button>
            <b-button :to="{ name: 'Donations', params: { lang: lang } }" variant="outline-primary" class="btn-icon">
              <img src="../../../assets/images/donate.png" alt="Donaciones">
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <menu-division />
    <div class="col-12">
      <router-view />
    </div>
    <!-- Carrusel de usuarios en linea -->
    <carousel-users-online :show-name="false" />
    <banner-carrusel :banners="banners" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import MenuDivision from  '../partials/MenuDivision'
import CarouselUsersOnline from '../../partials/CarouselUsersOnline.vue'
import BannerCarrusel from '../partials/Banners'

export default {
  components: {
    MenuDivision,
    CarouselUsersOnline,
    BannerCarrusel
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'divisionFront'
    ])
  },
  mounted () {
    this.fetchData()
  },
  data () {
    return {
      banners: []
    }
  },
  methods: {
    changeSeason(event) {
      const seasonId = parseInt(event.target.value)
      if (seasonId) {
        const seasons = this.divisionFront.seasons.filter(season => {
          return season.id === seasonId
        })
        if (seasons.length > 0) {
          const season = seasons.reduce(sea => {
            return sea
          })
          this.$store.dispatch('SET_CURRENT_SEASON', season.id)
        }
      }
    },
    fetchData () {
      const lang = this.$route.params.lang
      const conso = this.$route.params.console
      const slug = this.$route.params.divisionSlug
      const path = `${lang}/console/${conso}/division/${slug}`
      this.$axios.get(path).then((response) => {
        const data = response.data.data
        this.banners = data.banners
        this.$store.dispatch('SET_DIVISION_FRONT', data)
        // if (data.competition_type === 'ELIMINACION DIRECTA' || data.competition_type === 'ELIMINACION DIRECTA ESPECIAL') {
        //   this.$router.push({ name: 'DivisionLiguilla', params: { lang: this.lang, console: this.console, divisionSlug: slug } })
        // }
      })
    }
  }
}
</script>
